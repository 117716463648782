
/**
 * @name: 商品盘点记录
 * @author: itmobai
 * @date: 2023-07-24 10:26
 * @description：商品盘点记录
 * @update: 2023-07-24 10:26
 */
import { Vue, Component } from "vue-property-decorator"
import { ICrudOption } from "@/types/m-ui-crud";
import { mallStockCheckRecordQueryByPageApi, mallStockCheckRecordExportApi } from "@/apis/site/productRecords"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { IProductRecord, IProductRecordParam } from "@/apis/site/productRecords/types"
import config from "@/config";
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class productRecordPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IProductRecord[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IProductRecordParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<IProductRecord> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
    {
        label: "盘点记录ID",
        prop: "idKeywords",
        hide: true,
        search: true
      },
      {
        label: "盘点记录ID",
        prop: "id",
        align: "left",
        width: 180,
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        search: true,
        placeholder: "输入商品名称模糊搜索"
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
      },
      {
        label: "盘点前数量(份)",
        prop: "checkFrontQty",
        align: "center",
        width: 100,
      },
      {
        label: "盘点后数量(份)",
        prop: "checkBehindQty",
        align: "center",
        width: 100,
      },
      {
        label: "盘点结果",
        prop: "checkResultSel",
        hide: true,
        search: true,
        type: "select",
        dicData: [
          {
            label: "盘盈",
            value: 1
          },
          {
            label: "盘亏",
            value: 2
          },
          {
            label: "盘平",
            value: 3
          }
        ]
      },
      {
        label: "盘点结果",
        prop: "checkResultStr",
        align: "center",
        width: 100
      },
      {
        label: "盘点说明",
        prop: "checkDescription",
        align: "center",
        overHidden: true
      },
      {
        label: "相关图片",
        prop: "checkPic",
        width: 100,
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        imgSuffix: ";"
      },
      {
        label: "经办人",
        prop: "handledName",
        search: true,
        align: "center",
        overHidden: true
      },
      {
        label: "盘点时间",
        prop: "addTime",
        search: true,
        type: "daterange",
        align: "center",
        width: 180
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const form: IProductRecordParam = deepCopy(this.queryForm);
    if (this.queryForm.addTime && this.queryForm.addTime.length) {
      form.addTimeStart = this.queryForm.addTime[0]
      form.addTimeEnd = this.queryForm.addTime[1]
    } else {
      form.addTimeStart = ""
      form.addTimeEnd = ""
    }
    delete form.addTime;
    mallStockCheckRecordQueryByPageApi(form).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: IProductRecordParam = deepCopy(this.queryForm);
    if (this.queryForm.addTime && this.queryForm.addTime.length) {
      form.addTimeStart = this.queryForm.addTime[0]
      form.addTimeEnd = this.queryForm.addTime[1]
    } else {
      form.addTimeStart = ""
      form.addTimeEnd = ""
    }
    delete form.addTime;
    mallStockCheckRecordExportApi(form).then(e => {
      exportFile(e, '商品盘点记录.xlsx')
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
