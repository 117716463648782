import { render, staticRenderFns } from "./productRecords.vue?vue&type=template&id=47abf428&scoped=true&"
import script from "./productRecords.vue?vue&type=script&lang=ts&"
export * from "./productRecords.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47abf428",
  null
  
)

export default component.exports