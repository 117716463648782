/**
 * @name: 商品盘点记录类型
 * @author: itmobai
 * @date: 2023-07-24 10:29
 * @description：商品盘点记录类型
 * @update: 2023-07-24 10:29
 */
import { get } from "@/request";
import { IProductRecord, IProductRecordParam } from "./types"
import { IPageRes } from "@/apis/page";

/**
 * 商品记录分页查询
 * @param param 查询参数
 * @returns
 */
export const mallStockCheckRecordQueryByPageApi = (param: IProductRecordParam) => get<IPageRes<IProductRecord[]>>("/mall/stockCheckRecord/queryByPage", param)
/**
 * 导出
 * @param param
 * @returns
 */
export const mallStockCheckRecordExportApi = (param: IProductRecordParam) => get("/mall/stockCheckRecord/export", param, 'blob')
